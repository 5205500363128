import { combineReducers } from 'redux';

import * as routing from './routing';
import * as address from './address';
import * as rnft from './rnft';

// For Global State interface
export interface State {
  routing: routing.State;
  address: address.State;
  rnft: rnft.State;
}

export const defaultState: State = {
  routing: routing.defaultState,
  address: address.defaultState,
  rnft: rnft.defaultState,
};

const reducers = combineReducers<State>({
  ...routing.reducer,
  ...address.reducer,
  ...rnft.reducer,
});

export default reducers;
