import React, { MouseEvent } from 'react';

import { useHistory } from 'models/routing';

interface Props {
  className?: string;
  children: React.ReactChild;
  to: string;
}

const Link: React.FC<Props> = ({
  className = '',
  children = null,
  to = '',
}) => {
  const history = useHistory();

  const onClickHandler = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const location = {
      pathname: e.currentTarget.pathname,
    };

    history.push(location);
  };

  return (
    <a className={className} href={to} onClick={onClickHandler}>
      {children}
    </a>
  );
};

export default Link;
