import { handleActions } from 'redux-actions';

import { useRedux, WrapActionDispatch } from 'util/hook/redux';

import { State as GlobalState } from './reducers';

import metaData from 'data/more-want-metadata.json';
import rankData from 'data/more-want-rank.json';

export const CONTRACT_ADDRESS = '0x55ecd70df7b4369b0fd82ce841b3ba8037c034ec';

export enum RARITY {
  'ALL' = 'ALL',
  'S' = 'S',
  'SR' = 'SR',
  'SR+' = 'SR+',
  'SSR' = 'SSR',
  'UR' = 'UR',
}

export enum ALIGNMENTS {
  'ALL' = 'ALL',
  'TRUE_NEUTRAL' = 'True neutral',
  'NEUTRAL_EVIL' = 'Neutral evil',
  'NEUTRAL_GOOD' = 'Neutral good',
  'CHAOTIC_NEUTRAL' = 'Chaotic neutral',
  'CHAOTIC_EVIL' = 'Chaotic evil',
  'CHAOTIC_GOOD' = 'Chaotic good',
  'LAWFUL_NEUTRAL' = 'Lawful neutral',
  'LAWFUL_EVIL' = 'Lawful evil',
  'LAWFUL_GOOD' = 'Lawful good',
}

export enum SKIN {
  'ALL' = 'ALL',
  '(0, 0, 0)' = '(0, 0, 0)',
  'YELLOW' = 'yellow',
  '(255, 255, 255)' = '(255, 255, 255)',
  '#969696' = '#969696',
  'yellow, purple, banana' = 'yellow, purple, banana',
  'GOLDEN' = 'golden',
  '#BFBFBF' = '#BFBFBF',
}

export enum FIGURE {
  'ALL' = 'ALL',
  'BUNNY' = 'Demon King Figure (Bunny Ver.)',
  'ELECTROPLATED' = 'Demon King Figure (Electroplated Ver.)',
  'BLACK_WHITE' = 'Demon King Figure (Black & White Ver.)',
  'CROSSOVER' = 'Demon King Figure x Mickey Huang (Crossover Ver.)',
}

interface Attribute {
  Rarity: Exclude<RARITY, RARITY.ALL>;
  Alignments: Exclude<ALIGNMENTS, ALIGNMENTS.ALL>;
  Skin: Exclude<SKIN, SKIN.ALL>;
  HP: number;
  MP: number;
  STR: number;
  SAN: number;
  'Redeem figure': Exclude<FIGURE, FIGURE.ALL>;
}

interface MetaData {
  attributes: Attribute;
  external_url: string;
  image: string;
  name: string;
  animation_url: string;
  id: number;
}

interface MoreWantMetaData {
  [key: string]: MetaData;
}

// For Global State usage
export interface State {
  loading: boolean;
  data: MoreWantMetaData;
  rank: number[];
}

export const defaultState: State = {
  loading: false,
  data: metaData as unknown as MoreWantMetaData,
  rank: rankData.rank,
};

export const reducer = {
  rnft: handleActions<State, any>({}, defaultState), // eslint-disable-line @typescript-eslint/no-explicit-any
};

interface RankData extends MetaData {
  rank: number;
}

const mapHooksToState = (state: GlobalState): { moreWantData: RankData[] } => ({
  moreWantData: state.rnft.rank.map((id, i) => ({
    ...state.rnft.data[id],
    rank: i + 1,
  })),
});

const rnftActionsMap = {};

type RnftSelector = ReturnType<typeof mapHooksToState>;
type RnftActionsMap = typeof rnftActionsMap;

export const useRnft = (): [RnftSelector, WrapActionDispatch<RnftActionsMap>] =>
  useRedux<RnftSelector, RnftActionsMap>(mapHooksToState, rnftActionsMap);
