import React from 'react';

import SwapTheme from 'components/atoms/SwapTheme';
import Link from 'components/atoms/Link';

const Header: React.FC = () => (
  <div className="navbar bg-base-300 top-0">
    <div className="flex-1 px-2">
      <Link className="text-lg font-bold" to="/">
        Braverse
      </Link>
    </div>
    <div className="flex-none">
      <ul className="menu menu-horizontal p-0">
        <li className="mx-2">
          <Link to="/">More Want</Link>
        </li>
        <li className="mx-2">
          <Link to="/">PFP</Link>
        </li>
      </ul>
      <SwapTheme className="mx-2"></SwapTheme>
    </div>
  </div>
);

export default Header;
